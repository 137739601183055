@import "styles/font-awesome.min.css";
@import "assets/atlas-material/atlas-material";
@import "assets/themes";
@import "assets/layout";
@import "assets/atlas-icons/atlas-icons";
@import '../node_modules/dropzone/dist/min/dropzone.min.css';

@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/tippy.js/themes/light-border.css';
@import '../node_modules/tippy.js/themes/material.css';
@import '../node_modules/tippy.js/themes/light.css';
@import '../node_modules/tippy.js/animations/shift-away.css';
@import '../node_modules/tippy.js/dist/svg-arrow.css';

@import '~@angular/material/theming';
@import './assets/atlas-material/theming';
@include mat-core();
@include angular-material-theme($dynamic-app-theme);
@include angular-material-typography($dynamic-typography);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  //background: $atlas-background
}
body { margin: 0; font-family: Fira Sans, "Helvetica Neue", sans-serif; }

.chart-container {
  position: relative;
  height: 400px;
  //width: 100%;
}

.hidden {
  display: none;
}

.error-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: rgba(102, 102, 102, 0.87);
}

//angular material styling changes
.mat-card-header {
  justify-content: space-between;
  align-items: center;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-card {
  padding: 30px !important;
  border-radius: 14px !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  0 2px 4px rgba(0,0,0,0.07),
  0 4px 8px rgba(0,0,0,0.07) !important;
}

.mat-card-header {
  margin-bottom: 30px !important;
  align-items: baseline !important;
}

.mat-card-header .mat-card-title {
  margin: 0;
  font-size: 24px;
}

.tippy-box[data-theme~='material'] {
  padding: 8px;
}

.tippy-box[data-theme~='light'] {
  padding: 30px;
}

.tippy-box p {
  margin-bottom: 0;
}

.tippy-tooltip {
  overflow-wrap: break-word;
}

///////////////////////// buttons /////////////////////////////
$primary-purple: rgb(90, 46, 209);
$primary-highlighted-purple: #4824A7;
$disabled-purple: rgba(90, 46, 209, 0.5);

$primary-gray: #636363;
$disabled-gray: rgba(16, 16, 16, 0.3);
$primary-red: #ff4e50;

$purple-box-shadow: rgba(90, 46, 209, 0.2);
$red-box-shadow: rgba(255,78,80,0.2);

.btn {
  box-sizing: border-box;
  min-width: 100px;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s ease 0s;
  background: #FFFFFF;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-family: 'Open Sans', sans-serif;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
}

.delete-btn {
  border: 2px solid $primary-red;
  color: $primary-red;
  padding: 8px 16px;

  &:hover {
    background: $primary-red;
    color: #FFFFFF;
    box-shadow:
      0 5px 10px $red-box-shadow,
      0 8px 15px $red-box-shadow;
  }
}

.primary-btn {
  background: $primary-purple;
  color: #FFFFFF;
  padding: 12px 24px;
  border: none;

  &:hover {
    background: $primary-highlighted-purple;
    box-shadow:
      0 5px 10px $purple-box-shadow;
  }

  &:disabled,
  &[disabled] {
    background: $disabled-purple;
    pointer-events: none;
  }
}

.secondary-btn {
  border: 2px solid $primary-purple;
  padding: 10px 20px;
  color: $primary-purple;

  &:disabled {
    border-color: $disabled-purple;
    color: $disabled-purple;
  }
}

.tertiary-btn {
  padding: 10px 20px;
}

.datagrid-icon {
  font-size: 18px;
}

.datagrid-btn {
  color: $primary-purple;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.icon-btn {
  border: 2px solid $primary-purple;
  padding: 8px 16px;
  color: $primary-purple;

  &:hover,
  &:active {
    background: $primary-purple;
    color: #ffffff;
    box-shadow:
      0 5px 10px $purple-box-shadow,
      0 8px 15px $purple-box-shadow;
  }

  &:disabled {
    border: 2px solid $disabled-purple;
    color: $disabled-purple;
  }
}
.prefix-icon {
  margin-right: 7px;
}

.suffix-icon {
  margin-left: 7px;
}

.disabled-icon {
  color: $primary-red;
  font-size: 18px;
  margin-right: 10px;
}

.btn.save-btn {
  align-items: inherit;
  margin-left: 0;
}

.btn .mat-progress-spinner circle, .mat-spinner circle {
  stroke: #FFFFFF;
}

.no-margin-right {
  margin-right: 0;
}

.billing-wizard-form-field .mat-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-progress-bar-fill::after {
  background-color: #DEDEDE;
}
