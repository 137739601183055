@mixin atlas-button($theme) {

  .mat-button, .mat-icon-button, .mat-flat-button {
    line-height: 40px!important;
    margin-right: 5px !important;
  }

  .mat-stroked-button, .mat-raised-button {
    line-height: 36px!important;
    margin-left: 5px !important;
  }

  .mat-button-small {
    &.mat-button,
    &.mat-icon-button,
    &.mat-flat-button {
      line-height: 32px!important;
    }

    &.mat-stroked-button,
    &.mat-raised-button {
      line-height: 30px!important;
    }
  }

  .mat-button-xs {
    &.mat-button,
    &.mat-icon-button,
    &.mat-flat-button {
      line-height: 25px!important;
      padding: 2px !important;
      min-width: 0 !important;
    }

    &.mat-stroked-button,
    &.mat-raised-button {
      line-height: 25px!important;
    }
  }

  .mat-button-large {
    &.mat-button,
    &.mat-icon-button,
    &.mat-flat-button {
      line-height: 48px!important;
      padding-left: 25px;
      padding-right: 25px;
    }

    &.mat-stroked-button,
    &.mat-raised-button {
      line-height: 46px!important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  $primary: map-get($theme, primary);

  .mat-button.active {
    background-color: #FFFFFF;
    color: map-get($primary, 700);
    font-weight: bold;
  }

  .mat-warn {
    &.mat-stroked-button:not([disabled]) {
      border-color: #ff4e50;
      border-width: 2px;
      color: #ff4e50;
    }
  }

  .mat-primary {
    &.mat-flat-button .mat-ripple-element {
      background-color: black!important;
      opacity: 0.1!important;
    }

    &.mat-flat-button:hover,
    &.mat-mini-fab:hover,
    &.mat-fab:hover{
      background-color: map-get($primary, 400);
    }

    &.mat-flat-button[disabled][disabled] {
      //background-color: rgba(map-get($primary, 500), 0.5);
      //background-color: rgba(map-get($primary, 700), 1);
      //color: #fff;
    }

    &.mat-stroked-button:not([disabled]) {
      border-color: map-get($primary, 500);
      border-width: 2px;
    }

    &.mat-stroked-button:not([disabled]):hover {
      border-color: map-get($primary, 400);
      //background: map-get($primary, 500);
    }

    &.mat-stroked-button:not([disabled]):focus,
    &.mat-stroked-button:not([disabled]) .mat-ripple-element {
      //background-color: white;
      //border-color: map-get($primary, 700);
      //border-width: 1px!important;
    }

    &.mat-stroked-button[disabled][disabled] {
      //border-color: rgba(map-get($primary, 500), 0.5);
      //color: rgba(map-get($primary, 500), 0.5);
    }

    &.mat-raised-button {
      //background-color: #fff!important;
      //color: map-get($primary, 500) !important;
      //background: map-get($primary, 500) !important;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)!important;
      border: solid 1px transparent;
    }

    &.mat-raised-button.mat-primary {
      //background-color: #fff!important;
      //color: map-get($primary, 500) !important;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)!important;
      border: solid 1px transparent;
    }

    &.mat-raised-button:not([disabled]):hover {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25)!important ;
    }

    &.mat-raised-button:focus {
      border-color: map-get($primary, 700);
      color: rgba(map-get($primary, 500), 0.5);
      box-shadow: none!important;
    }

    &.mat-raised-button[disabled][disabled] {
      color: rgba(map-get($primary, 500), 0.5) !important;
    }

    .atlas-icon-fill {
      fill: map-get($primary, 500) !important;
    }

    .atlas-icon-stroke {
      stroke: map-get($primary, 500) !important;
    }
  }

  .mat-button-base.mat-primary {
    //&:hover:not([disabled]) {
    //  color: map-get($primary, 400) !important;
    //  .mat-button-focus-overlay {
    //    background-color: white;
    //  }
    //}

    &:focus:not(mat-flat-button) {
      color: map-get($primary, 700);
      .mat-button-focus-overlay {
        background-color: white;
      }
    }

    &.mat-flat-button .mat-ripple-element {
      background-color: black!important;
      opacity: 0.1!important;
    }

    &.mat-flat-button.cdk-focused {
      color: #fff;
    }

    &.mat-flat-button:hover,
    &.mat-mini-fab:hover,
    &.mat-fab:hover{
      //background-color: map-get($primary, 400);
    }

    &.mat-flat-button[disabled][disabled] {
      //background-color: rgba(map-get($primary, 500), 0.5);
      //color: #fff;
    }

    &.mat-stroked-button:not([disabled]) {
      //border-color: map-get($primary, 500);
    }

    &.mat-stroked-button:not([disabled]):hover {
      //border-color: map-get($primary, 400);
    }

    &.mat-flat-button:hover {
      //color: #fff !important;
    }

    &.mat-stroked-button:not([disabled]):focus,
    &.mat-stroked-button:not([disabled]) .mat-ripple-element {
      //background-color: white;
      //border-color: map-get($primary, 700);
      //border-width: 1px!important;
    }

    &.mat-stroked-button[disabled][disabled] {
      border-width: 2px;
    }

    &.mat-raised-button {
      //background-color: #fff!important;
      //color: map-get($primary, 500) !important;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)!important;
      border: solid 1px transparent;
    }

    &.mat-raised-button.mat-primary {
      //background-color: #fff!important;
      //color: map-get($primary, 500) !important;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)!important;
      border: solid 1px transparent;
    }

    &.mat-raised-button:not([disabled]):hover {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25)!important ;
    }

    &.mat-raised-button:focus {
      border-color: map-get($primary, 700);
      color: rgba(map-get($primary, 500), 0.5);
      box-shadow: none!important;
    }

    &.mat-raised-button[disabled][disabled] {
      color: rgba(map-get($primary, 500), 0.5) !important;
    }

    //&.mat-button {
    //  &:hover:not([disabled]) {
    //    color: map-get($primary, 400) !important;
    //    .mat-button-focus-overlay {
    //      //background-color: white;
    //    }
    //  }
    //
    //  &:focus {
    //    color: map-get($primary, 700)!important;
    //    .mat-button-focus-overlay {
    //      background-color: white;
    //    }
    //  }
    //}
  }

  a.mat-primary.mat-button .mat-button-wrapper {
    text-decoration: underline;
  }

  a.mat-primary.mat-button {
    padding-left: 2px !important;
    padding-right: 2px !important;
    line-height: 30px !important;
    min-width: 0 !important;
  }

  a {
    color: map-get($primary, 500);
  }

  .mat-menu-button, .mat-menu-icon {
    color: map-get($primary, 500) !important;
  }
}
